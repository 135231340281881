// Global function object
if (typeof Mates === 'undefined') {
  var Mates = {}
}

(function ($) {

  Mates.contentFeedSlider = function() {
    $('.content-feed-slideshow').bxSlider({
      'controls': false
    })
  }


  Mates.tabs = function () {
    $('.nav-tabs a').click(function (e) {
      e.preventDefault()
      $(this).tab('show')
    })
  }

  Mates.contentBlockSlideshow = function () {
    $('.content-block-slideshow').bxSlider({
      mode: 'fade',
      captions: true,
      adaptiveHeight: true,
      controls: false,
      pagerCustom: '.bx-pager',
    })
  }

  Mates.animateExpandCollapse = function () {
    $('.expand-collapse-trigger').click(function () {
      $(this).find('i').toggleClass('fa-rotate-180')
    })
  }

  Mates.showcase = $('#showcase').bxSlider({
    mode: 'fade',
    adaptiveHeight: true,
    auto: true,
    pause: 5000,
    autoHover: true,
    onSliderLoad: function () {
      var caption = $('#showcase .caption')
      var target = parseInt($('.container').first().css('margin-left')) + parseInt($('.container').first().css('padding-left'))
      caption.css('padding-left', target)
    }
  })

  if ($('#showcase').length) {
    // https://css-tricks.com/snippets/jquery/done-resizing-event/
    var resizeTimer
    $(window).on('resize', function (e) {

      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(function () {

        Mates.showcase.reloadSlider()

      }, 250)

    })
  }

  Mates.partnerMap = function () {
    Mates.partnerMap.map = new google.maps.Map(document.getElementById('partnerMap'), {
      styles: [
        {
          'featureType': 'all',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'saturation': 36
            },
            {
              'color': '#333333'
            },
            {
              'lightness': 40
            }
          ]
        },
        {
          'featureType': 'all',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'visibility': 'on'
            },
            {
              'color': '#ffffff'
            },
            {
              'lightness': 16
            }
          ]
        },
        {
          'featureType': 'all',
          'elementType': 'labels.icon',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'administrative',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#fefefe'
            },
            {
              'lightness': 20
            }
          ]
        },
        {
          'featureType': 'administrative',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#fefefe'
            },
            {
              'lightness': 17
            },
            {
              'weight': 1.2
            }
          ]
        },
        {
          'featureType': 'administrative.country',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'lightness': '0'
            },
            {
              'gamma': '1.00'
            },
            {
              'saturation': '0'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#f5f5f5'
            },
            {
              'lightness': 20
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'lightness': '-10'
            },
            {
              'gamma': '1.00'
            },
            {
              'saturation': '0'
            }
          ]
        },
        {
          'featureType': 'landscape',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'lightness': '0'
            },
            {
              'gamma': '1.00'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#f5f5f5'
            },
            {
              'lightness': 21
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#dedede'
            },
            {
              'lightness': 21
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#ffffff'
            },
            {
              'lightness': 17
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#ffffff'
            },
            {
              'lightness': 29
            },
            {
              'weight': 0.2
            }
          ]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#ffffff'
            },
            {
              'lightness': 18
            }
          ]
        },
        {
          'featureType': 'road.local',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#ffffff'
            },
            {
              'lightness': 16
            }
          ]
        },
        {
          'featureType': 'transit',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#f2f2f2'
            },
            {
              'lightness': 19
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#e9e9e9'
            },
            {
              'lightness': 17
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'lightness': '-20'
            },
            {
              'gamma': '1.00'
            },
            {
              'saturation': '0'
            }
          ]
        }
      ],
      minZoom: 4 // hardcoding this for this particular set of locations...
    })

    Mates.partnerMap.map.setZoom(10)

    var icon = {
      path: 'M-15,0a15,15 0 1,0 30,0a15,15 0 1,0 -30,0',
      fillColor: '#0698c9',
      fillOpacity: .7,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1
    }

    Mates.partnerMap.locations = []

    $.each($('.partners-list a'), function (index, value) {
      Mates.partnerMap.locations.push({
        lat: parseFloat($(this).data('lat')),
        lng: parseFloat($(this).data('lng')),
        title: $('span', $(this)).text(),
        target: $(this).attr('data-featherlight'),
        label: $(this).attr('data-number')
      })
    })

    Mates.partnerMap.bounds = new google.maps.LatLngBounds()

    var markers = Mates.partnerMap.locations.map(function (location, i) {

      var marker = new google.maps.Marker({
        position: {'lat': location.lat, 'lng': location.lng},
        map: Mates.partnerMap.map,
        title: location.title,
        label: location.label,
        icon: icon,
        target: location.target
      })

      Mates.partnerMap.bounds.extend(marker.position)

      marker.addListener('click', function () {
        $.featherlight(marker.target)
      })

      return marker

    })

    Mates.partnerMap.map.fitBounds(Mates.partnerMap.bounds)

    // var markerCluster = new MarkerClusterer(Mates.partnerMap.map, markers, {
    //         imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    //     }
    // );

  }

  Mates.popUpWindow = function (url) {
    var width = 800
    var height = 600

    var leftPosition, topPosition
    leftPosition = (window.screen.width / 2) - ((width / 2) + 10)
    topPosition = (window.screen.height / 2) - ((height / 2) + 50)

    var windowFeatures = 'status=no,height=' + height + ',width=' + width + ',resizable=yes,left=' + leftPosition + ',top=' + topPosition + ',screenX=' + leftPosition + ',screenY=' + topPosition + ',toolbar=no,menubar=no,scrollbars=no,location=no,directories=no'

    window.open(url, 'Social Share', windowFeatures)
  }

  Mates.openInPopup = function () {
    $(document).on('click', '.popup', function (e) {
      e.preventDefault()
      Mates.popUpWindow($(this).attr('href'))
    })
  }

  Mates.openLinksInNewTab = function () {
    $('a').each(function () {
      var a = new RegExp('/' + window.location.host + '/')
      var b = new RegExp(/#|javascript/)
      if (!a.test(this.href) && !b.test(this.href)) {
        $(this).click(function (event) {
          event.preventDefault()
          event.stopPropagation()
          window.open(this.href, '_blank')
        })
      }
    })
  }

  Mates.resources = function () {
    var containerEl = document.querySelector('.resources')
    var targetSelector = '.mix'
    var activeHash = ''

    /**
     * Deserializes a hash segment (if present) into in an object.
     *
     * @return {object|null}
     */

    var deserializeHash = function () {
      var hash = window.location.hash.replace(/^#/g, '')
      var obj = null
      var groups = []

      if (!hash) return obj

      obj = {}
      groups = hash.split('&')

      groups.forEach(function (group) {
        var pair = group.split('=')
        var groupName = pair[0]

        obj[groupName] = pair[1].split(',')
      })

      return obj
    }

    /**
     * Serializes a uiState object into a string.
     *
     * @param   {object}    uiState
     * @return  {string}
     */

    var serializeUiState = function (uiState) {
      var output = ''

      for (var key in uiState) {
        var values = uiState[key]

        if (typeof values === 'undefined') continue;

        if(!values.length) continue;

        output += key + '='
        output += values.join(',')
        output += '&'
      }

      output = output.replace(/&$/g, '')

      return output
    }

    /**
     * Constructs a `uiState` object using the
     * `getFilterGroupSelectors()` API method.
     *
     * @return {object}
     */

    var getUiState = function () {
      // NB: You will need to rename the object keys to match the names of
      // your project's filter groups – these should match those defined
      // in your HTML.
        var type;
        var topic;
        var tag;

        if($('[data-filter-group="topic"]').length){
            topic =  mixer.getFilterGroupSelectors('topic').map(getValueFromSelector);
        }
        if($('[data-filter-group="type"]').length){
            type =  mixer.getFilterGroupSelectors('type').map(getValueFromSelector);

        }
        if($('[data-filter-group="tag"]').length){
            tag =  mixer.getFilterGroupSelectors('tag').map(getValueFromSelector);

        }

      var uiState = {
        topic: topic,
          type: type,
          tag: tag
      }

      return uiState
    }

    /**
     * Updates the URL hash whenever the current filter changes.
     *
     * @param   {mixitup.State} state
     * @return  {void}
     */

    var setHash = function (state) {
      var selector = state.activeFilter.selector

      // Construct an object representing the current state of each
      // filter group

      var uiState = getUiState()

      // Create a URL hash string by serializing the uiState object

      var newHash = '#' + serializeUiState(uiState)

      if (selector === targetSelector && window.location.href.indexOf('#') > -1) {
        // Equivalent to filter "all", and a hash exists, remove the hash

        activeHash = ''

        history.replaceState(null, document.title, window.location.pathname)
      } else if (newHash !== window.location.hash && selector !== targetSelector) {
        // Change the hash

        activeHash = newHash

        history.replaceState(null, document.title, window.location.pathname + newHash)
      }
    }

    /**
     * Updates the mixer to a previous UI state.
     *
     * @param  {object|null}    uiState
     * @param  {boolean}        [animate]
     * @return {Promise}
     */

    var syncMixerWithPreviousUiState = function (uiState, animate) {
      var type = (uiState && uiState.type) ? uiState.type : []
      var topic = (uiState && uiState.topic) ? uiState.topic : []
      var tag = (uiState && uiState.tag) ? uiState.tag : []

        if($('[data-filter-group="topic"]').length){
            mixer.setFilterGroupSelectors('topic', topic.map(getSelectorFromValue))
        }
        if($('[data-filter-group="type"]').length){
            mixer.setFilterGroupSelectors('type', type.map(getSelectorFromValue))
        }
        if($('[data-filter-group="tag"]').length){
            mixer.setFilterGroupSelectors('tag', tag.map(getSelectorFromValue))
        }

      // Parse the filter groups (passing `false` will perform no animation)

      return mixer.parseFilterGroups(animate ? true : false)
    }

    /**
     * Converts a selector (e.g. '.green') into a simple value (e.g. 'green').
     *
     * @param   {string} selector
     * @return  {string}
     */

    var getValueFromSelector = function (selector) {
      return selector.replace(/^./, '')
    }

    /**
     * Converts a simple value (e.g. 'green') into a selector (e.g. '.green').
     *
     * @param   {string} selector
     * @return  {string}
     */

    var getSelectorFromValue = function (value) {
      return '.' + value
    }

    var uiState = deserializeHash()

    // Instantiate MixItUp

    var matchMixHeight = function () {
      $('.resources').imagesLoaded(function () {
        $('.mix:visible').matchHeight({
          byRow: true,
          property: 'height',
          target: null,
          remove: false
        })
      })
    }

    var mixer = mixitup(containerEl, {
      multifilter: {
        enable: true
      },
        load: {
            sort: 'featured:desc'
        },
      animation: {
        // effects: 'fade translateZ(-100px)'
      },
      callbacks: {
        onMixEnd: function (state) {
          setHash(state) // Call the setHash() method at the end of each operation
          matchMixHeight()
        }
      }
    })

    if (uiState) {
      // If a valid uiState object is present on page load, filter the mixer

      syncMixerWithPreviousUiState(uiState)
    }

    matchMixHeight()
  }

  Mates.ctaGroupMatchHeight = function() {
    if( $('.cta-group').length ) {
      $('cta-group h3').matchHeight();
    }
  }

  Mates.homePageContentFeedMatchHeight = function(){
      $('body.page-home .content-feed-body').matchHeight({
          target:$('.twitter')
      });
  }

  Mates.matchStandoutsHeight = function(){
      $('.standout').matchHeight();
  }

  $(document).ready(function () {
    Mates.tabs()
    Mates.contentBlockSlideshow()
    Mates.animateExpandCollapse()
    Mates.openInPopup()
    Mates.openLinksInNewTab()
    Mates.ctaGroupMatchHeight()
    Mates.matchStandoutsHeight();

    if ($('#partnerMap').length) {
      // Mates.partnersMap.redraw();
      // Mates.partnersMap.addMarkers();
      Mates.partnerMap()
    }

    if ($('.resources').length) {
      Mates.resources()
    }

    // to resolve occasional parallax bug
    jQuery(window).trigger('resize').trigger('scroll');

  })

  $(window).load(function() {
    Mates.contentFeedSlider();
      Mates.homePageContentFeedMatchHeight();
  })



})(jQuery)